import './Plans.css' 
import {plansData} from '../../data/plansData'
import whitetick from '../../assets/whiteTick.png'


const Plans = () => {
  return (
        <div className="plans-container">
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>

            

            <div className="plan-header">
                <span className='stroke-text'>Ready to start</span>
                <span >your journey</span>
                <span className='stroke-text'>now with us</span>
            </div>
            {/* Plans Card */}
            <div className="plans">
                {plansData.map((plan, i)=>(
                    <div className="plan" key={i}>
                        {plan.icon}   
                        <span>{plan.name}</span>
                        <span>$ {plan.price}</span>

                        <div className="features">
                            {plan.features.map((feature, i)=> (<div className="features">
                                <img src={whitetick} alt="" />
                            <span key={i}>{feature}</span>

                            </div>
                            ))}
                        </div>
                        <span>See more benefits </span>
                        <button className="btn"> Join Now</button>
                        
                       
                    </div>
                ))}

            </div>
        </div>
  )
}
export default Plans