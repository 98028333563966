import './Join.css'
import {useRef, useEffect} from 'react';
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dq7mq27', 'template_5dg9pnn', form.current, 'shXLLYQFQ2k2ROI92')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
        <div className="join" id="join">
            <div className="left-join">
                <hr />
                <div>
                    <span className='stroke-text'>READY TO</span>
                    <span> LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY</span>
                    <span className='stroke-text'> WITH US?</span>
                </div>
            </div>
            <div className="right-join">
            <form ref={form} className='email-container' action="
                " onSubmit={sendEmail}>
            <input type="email" name="user_email" placeholder='Enter your Email address to join'  />
                <button className='btn btn-join'>Join Now</button>
                </form>
            </div>
        </div>



  )
}
export default Join