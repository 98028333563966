import React from 'react';
import './App.css';
import Hero from './components/Hero.jsx';
import Programs from './components/Programs/Programs.jsx'
import Reasons from '../src/components/Reasons/Reasons';
import Plans from '../src/components/Plans/Plans.jsx'
import Testimonial from './components/Testimonial/Testimonial';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Hero/>
      <Programs />
      <Reasons />
      <Plans/>
      <Testimonial/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;
