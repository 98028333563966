import './Testimonial.css'
import {testimonialsData} from "../../data/testimonialsData"
import { useState } from 'react'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'
import {motion} from 'framer-motion'

const Testimonial = () => {

    const transition = {type: 'spring', duration: 3}


    // selected is index of our current active review
    const [selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;

  return (
    <div className="testimonials">
        {/* left */}
        <div className="left-testimonials">

        <span>Testimonials</span>
        <span className='stroke-text'>What they</span>
        <span>say about us</span>
        {/* actual testimonials data */}
        <motion.span
                key={selected}
                initial={{opacity: 0, x:-100}}
                animate={{opacity: 1, x:0}}
                exit={{opacity: 0,x:100}}
                transition={transition}
        > 
            {testimonialsData[selected].review}
        </motion.span>
        <span>
            <span>
                {testimonialsData[selected].name} 
            </span>
            {(" ")} 
                 :
                <span> {(" ")} 
            {testimonialsData[selected].status}
                 </span>
        </span>


        </div>
        {/* right */}
        <div className="right-testimonials">
            {/* the boxes around the pictures */}
        <motion.div
            initial={{opacity: 0, x: -100}}
            transition={{...transition, duration:2}}
            whileInView={{opacity: 1, x: 0}}

        ></motion.div>
        <motion.div
                        initial={{opacity: 0, x: 100}}
                        transition={{...transition, duration:2}}
                        whileInView={{opacity: 1, x: 0}}
        >



        </motion.div>

        <motion.img
        key={selected}
        initial ={{opacity: 0, x:100}}
        animate= {{opacity: 1, x: 0}}
        exit={{opacity: 0, x:-100}}
        transition={transition}
        src={testimonialsData[selected].image} alt="" />
 
            <div className="arrows">
                       {/* the logic explained, the left arrow will always be going back so its checking are we on the first testimonial? if we're not then -1 */}
                <img onClick={()=>
                    selected ===0
                    ?setSelected(tLength -1):
                    setSelected((prev)=> prev -1)
                }
                
                src={leftArrow} 
                alt="" />

                <img src={rightArrow} 
                    onClick={()=>
                        selected===tLength -1 ?
                        setSelected(0) :setSelected((prev) => prev +1)
                    }
                alt="" />
            </div>
        </div>

    </div>
  )
}
export default Testimonial